export default {
	setUser(user) {
		window.localStorage.setItem('my_user', JSON.stringify(user));
	},
	getUser() {
		return window.localStorage.getItem('my_user');
	},
	removeUser() {
		window.localStorage.removeItem('my_user');
	},
	setUserPrev(user) {
		window.localStorage.setItem('my_user_prev', JSON.stringify(user));
	},
	getUserPrev() {
		return window.localStorage.getItem('my_user_prev');
	},
	removeUserPrev() {
		window.localStorage.removeItem('my_user_prev');
	},
	setUnit(unit_id) {
		window.localStorage.setItem('my_unit', JSON.stringify(unit_id));
	},
	getUnit() {
		return window.localStorage.getItem('my_unit');
	},
	removeUnit() {
		window.localStorage.removeItem('my_unit');
	}
}