<template>
  <div>
    <CHeader position="sticky" class="mb-4">
      <CContainer fluid>
        <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
          <CIcon icon="cil-menu" size="lg" />
        </CHeaderToggler>
        <CHeaderBrand class="mx-auto d-lg-none" to="/">
          <CIcon :icon="logo" height="48" alt="Logo" />
        </CHeaderBrand>
          <div class="input-group">
            <CFormInput
              style="width: 80%;"
              class="form-control"
              placeholder="Busca tus clientes aqui"
              v-model="search"
              @blur="onBlur"
              @focus="show_list = true"
            >
            </CFormInput>
            <CInputGroupText id="basic-addon1">
               <CIcon :icon="cilSearch"/>
            </CInputGroupText>
          </div>
          
        <!--<CHeaderNav class="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink href="/dashboard"> Dashboard </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Settings</CNavLink>
          </CNavItem>
        </CHeaderNav>-->
        <CHeaderNav>

          <!--
          <CNavItem>
            <CNavLink href="#">
              <CIcon class="mx-2" icon="cil-list" size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CIcon class="mx-2" icon="cil-envelope-open" size="lg" />
            </CNavLink>
          </CNavItem>-->
          <CDropdown variant="nav-item" style="margin-top: 10px;">
            <CDropdownToggle placement="bottom-end" class="py-0" :caret="true">
               <CIcon class="mx-2" icon="cil-bell" size="lg" />
            </CDropdownToggle>
            <CDropdownMenu class="pt-0">
              <CDropdownItem  v-for="(notification,key) in notifications" :key="key"> 
                <CIcon icon="cil-task" /> 
                <router-link class="a_href"
                  :to="'/'+notification.link"
                  >{{notification.data}}
                </router-link>
                <CDropdownDivider/>
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
          <AppHeaderDropdownAccnt />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
       <CToaster placement="top-end" visible>
          <CToast
             v-for="not in notification"
            :key="'toast' + not.id"
            :show="true"
            header="Nueva notificación"
            
          >
            <CToastHeader closeButton></CToastHeader>
            <CToastBody>
              {{not.data}}
            </CToastBody> 
          </CToast>
      </CToaster>
    </CHeader>
    <CListGroup 
      id="list-customer"
      v-if="show_list && search.length >= 4" 
      style="position: absolute;z-index: 1200;background-color: #fff;width: 65%;margin-left: 90px;margin-top: -88px;"
    >
      <CListGroupItem v-for="customer in customers" :key="customer" style="cursor: pointer;" @click="selectCustomer(customer.id)">
        <CHeaderNavLink :id="'customer-'+customer.id" >
          {{customer.firstname +' '+customer.lastname}}
        </CHeaderNavLink>
      </CListGroupItem>
    </CListGroup>
  </div>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { logo } from '@/assets/brand/logo'
import axios from 'axios'
import PushJs from 'push.js'
import Pusher from 'pusher-js'
import { useUserStore } from '@/plugins/store';
import { cilSearch } from '@coreui/icons'

var TheHeader;

function getNotification(data) {
  TheHeader.addNotification(data);
  TheHeader.getMyNotifications();
}

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  setup() {

    const userStore = useUserStore();

    return {
      logo,
      userStore,
      cilSearch
    }
  },
  data(){
    return{
      search: '',
      show_list: false,
      user: null,
      notification:[],
      customers:[],
      awaiting_search: false,
      lastClickedElement: null
    }
  },
  mounted(){
    this.user = this.userStore.getUser
    this.getMyNotifications()

    TheHeader = this
    
    //Pusher.logToConsole = true;
   
    var pusher = new Pusher('91ab7afa0f283ea405d5', {
      cluster: 'eu'
    });
    
    var channel = pusher.subscribe('my-notification-'+this.user?.id);

    channel.bind(`my-notification-event`, function(data) {
      getNotification(data);
      
      PushJs.create("Hexcrm: Nueva Notificación",{
        body: data.data,
        icon: '/public/apple-icon-152x152.png',
        //timeout: 2000,
        onClick: function () {
          window.focus();
          TheHeader.$router.push({ path : data.link})
        }
      });
    });
  },
  methods:{
    addNotification(data){
      this.notification = [];
      this.notification.push(data);
    },
    onBlur() {
      document.body.addEventListener('click', this.hideList);
    },
    hideList() {
      this.show_list = false;
      document.body.removeEventListener('click', this.hideList);
    },
    getSearchCustomers(){
      axios({
        url: 'v1/customers/search',  
        method: 'POST', 
        data : {
          search: this.search 
        }
      })
      .then(resp => {
        const t = this
        t.customers = resp.data
      })
      .catch(err => {
        console.log(err)
      });
    },
     getMyNotifications(){
      axios({url: 'v1/users/notifications',  method: 'GET' })
        .then(resp => {
          const t = this
          t.notifications = resp.data.data
          t.total = resp.data.total
        })
        .catch(err => {
          console.log(err)
        });
    },
    selectCustomer(customer_id){
      this.show_list = false
      
      if(this.$route.name == 'Editar Cliente'){
        
        this.$router.push({
           name: 'Cargando Cliente',
           params: {
            customerId: customer_id
           }
        })
        return
        //location.href = '#/customers/'+customer_id+'/edit'
        //location.reload()
      }
      this.$router.push('/customers/'+customer_id+'/edit')
    }
  },
  watch: {
    search: function () {
      if (!this.awaiting_search) {
        setTimeout(() => {
          this.getSearchCustomers()
          this.show_list = true
          this.awaiting_search = false;
        }, 1000); // 1 sec delay
      }
      this.awaiting_search = true;
    },
  },
}
</script>
<style scoped>
  .input-group{
    width: 80% !important;
  }
    .a_href{
    color: inherit;
    text-decoration: none;
  }
</style>
