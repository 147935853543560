<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="baseURL+'img/profiles/'+user?.image" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        {{user?.firstname+' '+user?.lastname }}
      </CDropdownHeader>
      <CDropdownDivider/>
      <CDropdownItem>
        <CIcon name="cil-task" /> Tareas
        <CBadge color="danger" class="ml-auto">{{ task_items }}</CBadge>
      </CDropdownItem>
      <CDropdownDivider/>
      <CDropdownItem 
        v-if="user_prev != null" 
        @click="restore"
      >
        <CIcon name="cil-user" /> 
          Restaurar Sesión Anterior
      </CDropdownItem>
      <CDropdownDivider/>
      <CDropdownItem @click="logout"> 
        <CIcon icon="cil-lock-locked" /> 
        Logout 
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/8.jpg'
import jwtToken from '../plugins/jwt/jwt-token';
import user from '../plugins/jwt/user' ; 
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useUserStore } from '@/plugins/store';
import $ from 'jquery'

const Toast = Swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});

export default {
  name: 'AppHeaderDropdownAccnt',
  data () {
    return { 
      avatar: avatar,
      itemsCount: 42,
      baseURL : null,
      task_items : 0,
      user : null,
      user_prev : JSON.parse(user.getUserPrev())
    }
  },
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  created() {
    this.user = this.userStore.getUser
    this.baseURL = axios.defaults.baseURL;
    this.getItemsTasks();
  },
  mounted(){
    $('[placement="bottom-end"]').removeAttr('href')
  },
  methods: {
    getItemsTasks(){
      axios
      .get('v1/tasks')
      .then(resp => {
        this.task_items = resp.data.length;
      })
      .catch(err => console.log(err));
    },
     logout(){

      this.userStore.logout()
      .finally(() => {
        location.href= '#/pages/login'
      })
    },
    restore(){
      let user_ = JSON.parse(user.getUserPrev())
      
      this.userStore.restore(user_.id)
      .then(response => {
        user.setUser(response.data.user)
        user.removeUserPrev()
        jwtToken.setToken(response.data.access_token);
        this.getOrganization()
      })
      .catch(err => {
        console.log(err);
        Toast.fire({
          icon: 'error',
          title: 'ACCESO DENEGADO',
        })
        localStorage.removeItem('token')
      });
    },
    getOrganization(){
      this.userStore.getOrganization()
      .then(res => {
        console.log(res)
        this.getParents()
      })
      .catch(err => {
        console.log(err)
        localStorage.removeItem('token')
      });
    },
    getParents(){
      this.userStore.getParents()
      .then(res => {
        console.log(res)
        this.$router.go(0)
      })
      .catch(err => {
        console.log(err)
      });
    },
  } 
}
</script>
