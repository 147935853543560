export default [
  /*{
    component: 'CNavItem',
    name: 'Organigrama',
    to: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      color: 'primary',
      text: 'NEW'
    }
  },
  {
    component: 'CNavItem',
    name: 'Usuarios',
    to: '/users',
    icon: 'cil-people'
  },*/
  {
    component: 'CNavItem',
    name: 'Clientes',
    to: '/customers',
    icon: 'cil-people'
  },
  {
    component: 'CNavItem',
    name: 'Tipo de Productos',
    to: '/product_types',
    icon: 'cil-puzzle'
  },
  {
    component: 'CNavItem',
    name: 'Tareas',
    to: '/tasks',
    icon: 'cil-puzzle'
  },
  {
    component: 'CNavItem',
    name: 'Recordatorio',
    to: '/reminders',
    icon: 'cil-puzzle'
  },
  {
    component: 'CNavItem',
    name: 'Prospectos',
    to: '/prospects',
    icon: 'cil-people',
  },
  {
    component: 'CNavItem',
    name: 'Revisión',
    to: '/revisions',
    icon: 'cil-puzzle'
  },
  {
    component: 'CSidebarNavDivider',
    _class: 'm-2'
  },
]