import { defineStore } from "pinia";
import { useLocalStorage } from '@vueuse/core'
import jwtToken from "@/plugins/jwt/jwt-token.js";
import user from "@/plugins/jwt/user.js";
import axios from 'axios'

export const useUserStore = defineStore("user", {
  state: () => ({
    user: useLocalStorage('user', null),
    unit: useLocalStorage('unit', null),
    parent: useLocalStorage('parent', null),
  }),
  actions: {
    async fetchUser() {
      const res = await axios.post("/user");

      const user = JSON.stringify(res.data.user)
      this.user = user;
    },
    async signUp(first_name, last_name, email, phone, code_postal, password, password_confirmation) {
      const res = await axios.post("/api/auth/register",{
        first_name,
        last_name,
        email,
        phone,
        code_postal,
        password, 
        password_confirmation 
      });
     
      res.data.user ? this.user = JSON.stringify(res.data.user) : null
     
      return res
    },
    async signUpProvider(name, email, image, auth_provider) {
      const res = await axios.post("/auth/provider/login",{
        name,
        email,
        image,
        auth_provider
      });
     
      res.data.user ? this.user = JSON.stringify(res.data.user) : null
     
      return res
    },
    async signIn(email, password) {
      const res = await axios.post("auth/login",{
         email, 
         password,
      });
      res.data.user ? this.user = JSON.stringify(res.data.user) : null
      res.data.access_token ? jwtToken.setToken(res.data.access_token) : null
      return res
    },
    async supplant(user_id) {
      const res = await axios.post('/users/supplant',{
        user_id: user_id
      })
      res.data.user ? this.user = JSON.stringify(res.data.user) : null
      res.data.access_token ? jwtToken.setToken(res.data.access_token) : null
      return res
    },
    async restore(user_id) {
      const res = await axios.post('/users/restore',{
        user_id: user_id
      })
      res.data.user ? this.user = JSON.stringify(res.data.user) : null
      res.data.access_token ? jwtToken.setToken(res.data.access_token) : null
      return res
    },
    async getOrganization(){
      const res = await axios.get("organizations/mine");
      res.data ? this.unit = JSON.stringify(res.data[0].unit_id) : null
      res.data[0].unit_id ? user.setUnit(res.data[0].unit_id) : null
      return res
    },
    async getParents(){
      const user = JSON.parse(this.user) 
      const res = await axios.get("organizations/parent/"+user.id+'/'+this.unit);
      res.data ? this.parent = res.data.parent_id : null
      return res
    },
    async editProfile(data) {
      const res = await axios.post("/auth/editProfile",data);
      res.data ? this.user = JSON.stringify(res.data.user) : null
      console.log(res.data.user)
      return res
    },
    async logout() {
      const res = await axios.post("auth/logout");
      this.user = null
      this.unit = null
      this.parent = null
      return res
    },
  },
  getters: {
    getUser(state) {
      return JSON.parse(state.user)
    },
    getUnit(state) {
      return JSON.parse(state.unit)
    },
    getParent(state) {
      return JSON.parse(state.parent)
    },
  },
});
