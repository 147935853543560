<template>
  <div>
    <button @click="$router.go(-1)" class="btn btn-primary float-left">
      <CIcon icon="cilArrowLeft"></CIcon>
    </button>

    <div style="margin-top:1.5px;"></div>
  </div>
</template>

<script>

export default {
  name: "BackButton",
};
</script>
